import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "@/components/Layout";
import News from "@/components/Carts/NewsCart";
import { SubPage } from "@/components/subPage";
import { MdArrowBackIosNew } from "react-icons/md";
import usePosts from "@/hooks/usePosts";
import convertSlug from "@/utils/convertSlug";
import { CustomRenderer } from "@/utils/CustomRenderer";
import { StaticImage } from "gatsby-plugin-image";
import gsap, { Linear } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const Home = ({ data, ...props }: any) => {
  const posts = usePosts();
  const { settings } = props.pageContext;

  const { content, childPage, title, slug } = data?.cms.page;
 React.useEffect(() => {
   gsap.config({
     nullTargetWarn: false,
   });
   gsap.registerPlugin(ScrollTrigger);
   gsap
     .timeline({
       scrollTrigger: {
         trigger: ".container__news",
         start: "top 100",
         endTrigger: "top",
         scrub: true,
         end: "bottom 50%+=200px",
       },
     })

     .to(".background__news", {
       y: 400,
       duration: 2,
       ease: Linear.easeNone,
     });
 }, []);
  return (
    <>
      <Layout title={title} settings={settings}>
        <div className=" bg-white pt-[92px]">
          {childPage.length !== 0 && <SubPage props={childPage} />}
          <CustomRenderer document={content.document} />

          {convertSlug(title) === "news" && (
            <>
              <div className="container__news  relative h-auto w-screen overflow-hidden mt-[-84px]">
                <StaticImage
                  className="background__news !absolute top-[50%] left-[50%] -translate-y-[30%] -translate-x-[50%] w-screen"
                  src="../images/background/industrial_background.jpeg"
                  alt=""
                  layout="fullWidth"
                  placeholder="none"
                  // quality={100}
                />
                <div className="z-5 relative grid grid-cols-2  py-[62px] px-6 text-white lg:container  ">
                  <div>
                    <h1 className="my-24 text-[48px] font-[600]">News</h1>
                  </div>

                  <div className=" flex items-center justify-end text-[#bdbdbd]">
                    <Link
                      to="/"
                      className="cursor-pointer relative before:absolute before:right-0 before:bottom-0 before:h-[4px] before:w-[0%] before:bg-theme before:transition-width hover:before:left-0 hover:before:w-[100%]"
                    >
                      Home
                    </Link>
                    <span className="relative inline-block w-8 items-center  text-[10px] text-theme ">
                      <MdArrowBackIosNew className="rotate-180 mx-auto" />
                    </span>
                    News
                  </div>
                </div>
              </div>
              <article className="xl:container">
                <div className="px-[5%] relative lg:px-0 mt-[2rem]">
                  <section className="columns-1 sm:columns-2 lg:columns-3 min-h-full break-after-avoid break-before-avoid	break-inside-avoid	">
                    {posts.map((post: any) => (
                      <News key={post.id} data={post} />
                    ))}
                  </section>
                </div>
              </article>
            </>
          )}
          {convertSlug(title) === "home" && (
            <article className="mt-24 grid grid-cols-1 gap-y-10 px-[5%] xl:px-0 xl:container ">
              <div className="md:flex justify-between  block">
                <h3 className="relative mb-6  border-l-4 border-theme pl-[12px] text-[14px] font-[800] uppercase leading-[26px] tracking-[0.5px] text-dark">
                  Latest News
                </h3>
                <Link to="/news">
                  <button className="relative mx-auto mr-48 min-w-[200px]  bg-secondaryDark pt-[12px] pb-[13px] before:absolute before:right-0 before:bottom-0 before:h-full  before:w-0  before:bg-black before:transition-width  before:duration-700 before:ease-in-expo hover:before:left-0 hover:before:w-full hover:after:w-0 hover:after:transition-width ">
                    <span className="relative  flex w-auto items-center justify-center  text-[16px] font-[600] text-white">
                      More news
                      <span className="bottom-0 ml-1 translate-y-[1.5px] text-center text-[9px] font-[800]">
                        <MdArrowBackIosNew className="rotate-180" />
                      </span>
                    </span>
                  </button>
                </Link>
              </div>

              <div className="mx-auto lg:columns-3 sm:columns-2 h-auto">
                {posts.slice(0, 3).map((post: any) => {
                  return <News key={post.id} data={post} />;
                })}
              </div>
            </article>
          )}
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query Home($id: ID) {
    cms {
      page(where: { id: $id }) {
        title
        id
        slug
        content {
          document(hydrateRelationships: true)
        }
        localization {
          code
        }
        childPage {
          id
          title
          content {
            document(hydrateRelationships: true)
          }
        }
      }
    }
  }
`;
export default Home;
