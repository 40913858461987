import React, { ReactNode } from "react";

type ButtonProps = {
  href: string;
  name: ReactNode;
};

export const Button = ({ href, name }: ButtonProps) => {
  return (
    <div className="xl:container w-full h-full min-h-[1rem] pb-4">
      <a
        href={href}
        className=" w-full h-full  border-theme text-theme hover:bg-theme hover:text-white border-[1px] text-center flex justify-center items-center "
      >
        {name}
      </a>
    </div>
  );
};
