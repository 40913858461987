import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { KeystoneImage } from "gatsby-image-keystone";

type sliderProps = {
  children: [];
  appearance: any;
};
export const ImageSlider = ({ children, appearance }: sliderProps) => {
  return (
    <div className="xl:container">
      <Swiper
        spaceBetween={0}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 3,
          },

          800: {
            slidesPerView: 5,
          },
        }}
      >
        {children.map((e: any) => {
          const image = e.featureImage?.data?.image;

          return (
            <SwiperSlide key={image?.id}>
              <div className="relative group">
                {e.featureImage && (
                  <KeystoneImage
                    baseUrl="fortune"
                    className="grayscale group-hover:grayscale-0  relative"
                    image={{
                      alt: image?.id,
                      width: 200,
                      height: image?.height * (200 / image?.width),
                      key: `${image?.id}.${image?.extension}`,
                    }}
                    alt={image?.id}
                    layout="constrained"
                  />
                )}
                {e.link.discriminant && (
                  <a
                    href={e.link.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute top-0 left-0 h-full w-full group-hover:p-0 "
                  ></a>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
