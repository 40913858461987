import * as React from "react";
import { KeystoneImage } from "gatsby-image-keystone";
import gsap, { Linear } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Link } from "gatsby";
import { MdArrowBackIosNew } from "react-icons/md";

type BackgroundProps = {
  content: React.ReactNode;
  appearance: string;
  featureImage?: {
    data: {
      image: {
        id: string;
        height: number;
        width: number;
        extension: string;
      };
    };
  };
};

export const Background = ({
  content,
  appearance,
  featureImage,
}: BackgroundProps) => {
  const imageId = featureImage?.data?.image?.id;
  gsap.config({
    nullTargetWarn: false,
  });
  // React.useEffect(() => {
  //   if (imageId) {
  //     const tl = gsap
  //       .timeline({
  //         scrollTrigger: {
  //           trigger: `.imageId`,
  //           start: "bottom",
  //           endTrigger: "top",
  //           scrub: true,
  //           end: "bottom 50%-=200px",
  //         },
  //       })
  //       .to(".background", {
  //         y: 500,
  //         duration: 3,
  //         ease: Linear.easeNone,
  //       });

  //     setTimeline(tl);
  //   }
  // }, [imageId]);

  // React.useEffect(() => {
  //     gsap.registerPlugin(ScrollTrigger);
  // }, []);
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: `.imageId`,
          start: " bottom",
          endTrigger: "top",
          scrub: true,
          end: "bottom 50%-=200px",
        },
      })

      .to(".background", {
        y: 500,
        duration: 3,
        ease: Linear.easeNone,
      });
  }, []);

  const breadcrumbs = (
    <div className="z-5 relative grid grid-cols-2  py-[62px] px-6 text-white lg:container  ">
      <div>
        <h1 className="my-24 text-[48px] font-[600]">News</h1>
      </div>

      <div className=" flex items-center justify-end text-[#bdbdbd]">
        <Link
          to="/"
          className="cursor-pointer relative before:absolute before:right-0 before:bottom-0 before:h-[4px] before:w-[0%] before:bg-theme before:transition-width hover:before:left-0 hover:before:w-[100%]"
        >
          Home
        </Link>
        <span className="relative inline-block w-8 items-center  text-[10px] text-theme ">
          <MdArrowBackIosNew className="rotate-180 mx-auto" />
        </span>
        News
      </div>
    </div>
  );

  return (
    <div className="relative w-full min-h-fit">
      {featureImage?.data?.image && (
        <>
          <div
            key={`${imageId}-desktop`}
            className={`imageId hidden md:block relative mt-8 h-[290px] w-screen max-w-none overflow-hidden`}
          >
            <KeystoneImage
              baseUrl="fortune"
              className="background absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] w-full"
              image={{
                alt: featureImage?.data?.image.id,
                width: 1200,
                height:
                  featureImage.data.image.height *
                  (1200 / featureImage.data.image.width),
                key: `${imageId}.${featureImage.data.image.extension}`,
              }}
              alt={featureImage?.data?.image.id}
              layout="constrained"
            />
          </div>
          <div
            key={`${imageId}-mobile`}
            className={`imageId md:hidden relative mt-8 h-[290px] w-screen max-w-none overflow-hidden`}
          >
            <KeystoneImage
              baseUrl="fortune"
              className="background absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]"
              image={{
                alt: featureImage?.data.image.id,
                width: 800,
                height:
                  featureImage.data.image.height *
                  (800 / featureImage.data.image.width),
                key: `${featureImage.data.image.id}.${featureImage.data.image.extension}`,
              }}
              alt={featureImage?.data.image.id}
              layout="fixed"
            />
            {breadcrumbs}
          </div>
        </>
      )}
      <div className="text-white absolute z-30  w-full  md:px-40 px-5 top-[50%] -translate-y-[50%]">
        {content}
      </div>
    </div>
  );
};
