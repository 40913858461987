import * as React from "react";
import { KeystoneImage } from "gatsby-image-keystone";
import { AiOutlineCheckSquare } from "react-icons/ai";

type GridProps = {
  children: [];
};

export const GridCheck = ({ children }: GridProps) => {
  const grid = children?.map((data: any) => {
    return (
      <div
        key={data.content.key}
        className="mb-[1rem] flex gap-[1rem] justify-between hover:bg-theme bg-white duration-300 ease-in-out hover:text-white px-[1rem] pb-[2rem]"
      >
        <div className="mt-[3rem]">{data.content}</div>
        <div className="bg-[#EAEAEA] h-max p-[1rem] mt-[1rem]  text-theme">
          <AiOutlineCheckSquare className="" size={20} />
        </div>
      </div>
    );
  });
  return (
    <div className="xl:container grid sm:grid-cols-2 grid-cols-1 gap-[1rem] lg:grid-cols-3 px-[20px] xl:px-0">
      {grid}
    </div>
  );
};
