import * as React from "react";
import { GiWorld } from "react-icons/gi";
import { FiTrendingUp } from "react-icons/fi";
import { MdDynamicFeed } from "react-icons/md";
import { AiOutlineGlobal } from "react-icons/ai";

type GridMini = {
  children: [];
};
const CartIcon = [GiWorld, FiTrendingUp, MdDynamicFeed, AiOutlineGlobal];

export const GridMini = ({ children }: GridMini) => {
  const grid = children?.map((data: any, index) => {
    const Icon = CartIcon[index];
    return (
      <div
        key={data.content.key}
        className="shadow-lg flex gap-[10px] px-[15px] py-[10px]  hover:-translate-y-[5px] duration-200 ease-in-out [&>*]:text-[13px] [&>*]:my-auto bg-white"
      >
        <span className="bg-theme p-[8px] ">
          <Icon size={30} className="" color="white" />
        </span>
        {data.content}
      </div>
    );
  });
  return (
    <div className="px-[20px] xl:px-0 xl:container grid sm:grid-cols-2 grid-cols-1 gap-[1rem] mx-auto md:grid-cols-3 lg:grid-cols-4 pb-[2rem]">
      {grid}
    </div>
  );
};
