import React, { ReactNode } from "react";
import * as styles from "./Quote.module.css";

type QuoteProps = {
  attribution: ReactNode;
  content: ReactNode;
};

export function Quote({ attribution, content }: QuoteProps) {
  return (
    <div className="xl:container">
      <div className={styles.quote}>
        <div className="py-10">
          <div style={{ fontStyle: "italic", color: "#4A5568" }}>{content}</div>
          <div style={{ fontWeight: "bold", color: "#47546b" }}>
            — {attribution}
          </div>
        </div>
      </div>
    </div>
  );
}
