import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { KeystoneImage } from "gatsby-image-keystone";
import { Autoplay, Pagination } from "swiper";
import "./articleSlider.css";
import { FaMagento } from "react-icons/fa";
import { useIntl } from "gatsby-plugin-intl";

type sliderProps = {
  children: [];
  appearance: any;
};

export const ArticleSlider = ({ children }: sliderProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const { formatMessage: fm } = useIntl();

  const Sliders = children.map((e: any) => {
    const image = e.featureImage?.data?.image;
    return (
      <SwiperSlide key={e.content.key}>
        <div className="grid lg:px-[6rem] sm:grid-cols-2 lg:gap-[4rem] grid-cols-1 my-[3rem]">
          <div>
            {e.featureImage && (
              <KeystoneImage
                baseUrl="fortune"
                image={{
                  alt: image?.id,
                  width: 300,

                  height: image?.height * (300 / image?.width),
                  key: `${image?.id}.${image?.extension}`,
                }}
                alt={image?.id}
                layout="fullWidth"
              />
            )}
          </div>

          <div className="drop-shadow-2xl relative p-[3rem] bg-white mr-[4rem] h-max my-auto">
            {e.content}
            {e.link.discriminant && (
              <a
                href={e.link.value}
                target="_blank"
                rel="noopener noreferrer"
                className=" bottom-0 left-0 m-[3rem]"
              >
                <p className="font-[700]  w-max relative text-dark text-[12px] md:text-[16px] duration-500 before:absolute before:right-0 before:bottom-0 before:h-[2px] before:w-full before:bg-theme before:transition-width hover:before:left-0 hover:before:w-0 ">
                  {fm({ id: "newsReadArticle" })}
                </p>
              </a>
            )}
          </div>
        </div>
      </SwiperSlide>
    );
  });
  return (
    <div>
      <Swiper
        slidesPerView={1}
        pagination={pagination}
        loop={true}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 2300,
          disableOnInteraction: true,
        }}
      >
        {Sliders}
      </Swiper>
    </div>
  );
};
