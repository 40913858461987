import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { KeystoneImage } from "gatsby-image-keystone";

type sliderProps = {
  children: [];
  appearance: any;
};

export const GridSlider = ({ children, appearance }: sliderProps) => {
  return (
    <div>
      <Swiper
        slidesPerView={3}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          540: {
            slidesPerView: 2,
          },
          820: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={0}
        loop={true}
        className="bg-white"
      >
        {children.map((e: any) => {
          const image = e.featureImage?.data?.image;
          return (
            <SwiperSlide className="mb-[3rem] " key={e.content.key}>
              <div className="relative group overflow-hidden ">
                <div className="absolute w-max bottom-[1rem] [&>*]:text-white [&>*]:mb-0 [&>*]:font-[400] z-20 left-[50%] -translate-x-[50%]">
                  {/* <div className="flex gap-[1rem]">
                  <p className="">Mongolia&#39;s first hockey arena</p>
                  <span className="w-[3rem] h-[1px] bg-white my-auto"></span>
                </div>
                <h1 className="font-[400]">Steppe Arena</h1> */}
                  {e.content}
                </div>

                <span className="w-full h-full bg-gradient-to-t from-[#000000] absolute z-10 ease-in-out duration-200 opacity-80"></span>
                {e.featureImage && (
                  <KeystoneImage
                    baseUrl="fortune"
                    className=" group-hover:scale-105 duration-300 ease-in-out"
                    image={{
                      alt: image.id,
                      width: 600,
                      height: image.height * (600 / image.width),
                      key: `${image.id}.${image.extension}`,
                    }}
                    alt={image.id}
                    layout="fullWidth"
                  />
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
