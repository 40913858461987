import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import * as styles from "./Tweet.module.css";

type TweetProps = {
  url: string;
};

export function Tweet({ url }: TweetProps) {
  const wrapper = React.useRef<HTMLQuoteElement>(null);

  const { formatMessage: fm } = useIntl();
  React.useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    wrapper.current!.appendChild(script);
  }, []);

  return (
    <div className={styles.tweet}>
      <blockquote
        ref={wrapper}
        className="twitter-tweet"
        data-conversation="none"
      >
        <a href={url}>{fm({ id: "loadingTweet" })}...</a>
      </blockquote>
    </div>
  );
}
