import Layout from "@/components/Layout";
import { CustomRenderer } from "@/utils/CustomRenderer";
import React, { useState } from "react";
interface SubPageProps {
  props: any;
}
export const SubPage = ({ props }: SubPageProps) => {
  const NavigationStyling = `text-[12px] lg:text-[16px]  hover:border-theme hover:border-b-2 px-[10px] text-theme`;
  const ActiveNavigationStyling = `text-[12px] lg:text-[16px] text-theme border-b-2 border-theme px-[10px]`;
  const [activeTab, setActiveTab] = useState(props[0]);

  return (
    <div>
      {/* BG Image */}
      <div className="lg:flex px-[20px] xl:px-0 xl:container gap-[2rem] my-[2rem]">
        {/* Tabs */}
        <div className="lg:flex-row lg:min-w-max">
          <div className="lg:min-w-max mx-auto flex lg:flex-wrap lg:block lg:p-0 cursor-pointer">
            {props.map((tab: any) => (
              <div className="my-auto" key={tab.id}>
                <div
                  className={
                    activeTab === tab
                      ? ActiveNavigationStyling
                      : NavigationStyling
                  }
                  onClick={() => setActiveTab(tab)}
                >
                  <p className="px-[16px] py-[12px]  md:text-[16px] text-[13px] font-[400">
                    {tab.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Content */}
        <div className=" my-[2rem] lg:pr-[2rem] w-full">
          <CustomRenderer document={activeTab.content.document} />
        </div>
      </div>
    </div>
  );
};
