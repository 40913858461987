import React, { ComponentProps } from "react";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { Hero } from "@/components/Hero/Hero";
import { Callout } from "@/components/Callout/Callout";
import { Quote } from "@/components/Quote/Quote";
import { Carousel } from "@/components/Carousel/Carousel";
import { Tweet } from "@/components/Tweet/Tweet";
import { YouTubeVideo } from "@/components/YouTubeVideo/YouTubeVideo";
import { Image } from "@/components/Image";
import Swiper from "@/components/Swipers/Swiper";
import { Background } from "@/components/Background";
import { Title } from "@/components/Title";
import { GridItems } from "@/components/Grid/grid";
import { GridRows } from "@/components/Grid/gridRows";
import { GridCheck } from "@/components/Grid/gridCheck";
import { GridMini } from "@/components/Grid/gridMini";
import { Button } from "@/components/Button";
import { Form } from "@/components/Form";
import { ImageCard } from "@/components/Grid/imageCard.";
import { ArticleSlider } from "@/components/Swipers/articleSlider";
import { GridSlider } from "@/components/Swipers/gridSldier";
import { ImageSlider } from "@/components/Swipers/imageSlider";

type CustomRendererProps = ComponentProps<typeof DocumentRenderer>;

const defaultElementRenderers: CustomRendererProps["renderers"] = {
  block: {
    block: React.Fragment,
    divider: () => {
      return (
        <div className="my-5 max-w-[550px] mx-auto">
          {" "}
          <hr />
        </div>
      );
    },
    paragraph: ({ children, textAlign }) => {
      let textStyle = "w-full text-left mb-4";
      if (textAlign == "end") {
        textStyle = "text-right w-full mb-4";
      } else if (textAlign == "center") {
        textStyle = "text-center w-full mb-4";
      }

      return <p className={textStyle}>{children}</p>;
    },
    blockquote: ({ children }) => {
      return (
        <blockquote className="border-l-4 border-theme text-black">
          <div className="text-[28px] leading-[42px]  pl-4">{children}</div>
        </blockquote>
      );
    },
    list: ({ children, type }) => {
      if (type === "ordered") {
        return (
          <ol>
            {" "}
            {children.map((child) => (
              <li className="mb-4" key={children.indexOf(child)}>
                {child}
              </li>
            ))}
          </ol>
        );
      } else {
        return (
          <ul className="list-inside list-disc ">
            {children.map((child) => (
              <li className="mb-4" key={children.indexOf(child)}>
                {child}
              </li>
            ))}
          </ul>
        );
      }
    },
    heading: ({ children, textAlign, level }) => {
      switch (level) {
        case 1:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h1>{children}</h1>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h1>{children}</h1>
              </div>
            );
          } else {
            return <h1 className="">{children}</h1>;
          }
        case 2:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h2>{children}</h2>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h2>{children}</h2>
              </div>
            );
          } else {
            return <h2 className="">{children}</h2>;
          }
        case 3:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h3>{children}</h3>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h3>{children}</h3>
              </div>
            );
          } else {
            return <h3 className="">{children}</h3>;
          }
        case 4:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right">
                <h4>{children}</h4>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center">
                <h3>{children}</h3>
              </div>
            );
          } else {
            return <h3 className="">{children}</h3>;
          }
        case 5:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right lg:text-[18px]">
                <h5>{children}</h5>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-center lg:text-[18px]">
                <h5>{children}</h5>
              </div>
            );
          } else {
            return (
              <h5 className="font-[500] lg:text-[18px] lg:text-justify">
                {children}
              </h5>
            );
          }
        case 6:
          if (textAlign == "end") {
            return (
              <div className="w-full text-right lg:text-[16px]">
                <h6>{children}</h6>
              </div>
            );
          } else if (textAlign == "center") {
            return (
              <div className="w-full text-justify lg:text-[16px]">
                <h6>{children}</h6>
              </div>
            );
          } else {
            return <h6 className="font-[300] lg:text-[16px] ">{children}</h6>;
          }
        default:
          return <h6 className="">{children}</h6>;
      }
    },
    layout: ({ children, layout }) => {
      return (
        <div
          className={`xl:container xl:px-0 px-5 py-5
            grid gap-10  grid-cols-1 md:grid-cols-2  `}
          // className={`xl:container xl:px-0 px-5 py-5
          //   grid gap-10  grid-cols-1 md:grid-cols-[${layout
          //     .map((x) => `_${x}fr`)
          //     .join("")}]`}

          // className="xl:container py-[1rem] grid gap-10 grid-cols-2 "
        >
          {children.map((element, i) => (
            <div className="" key={i}>
              {element}
            </div>
          ))}
        </div>
      );
    },
  },
  inline: {
    bold: ({ children }) => {
      return <strong>{children}</strong>;
    },
    italic: ({ children }) => {
      return <em>{children}</em>;
    },
    link: ({ children, href }) => {
      return (
        <a
          className="text-theme hover:text-black"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    },
  },
};

const customComponentRenderers: CustomRendererProps["componentBlocks"] = {
  hero: (props) => {
    return <Hero {...props} />;
  },
  callout: (props) => {
    return <Callout {...props} />;
  },
  quote: (props) => {
    return <Quote {...props} />;
  },
  carousel: (props) => {
    return <Carousel {...props} />;
  },
  tweet: (props) => {
    return <Tweet {...props} />;
  },
  youtubeVideo: (props) => {
    return <YouTubeVideo {...props} />;
  },
  background: (props) => {
    return <Background {...props} />;
  },

  image: (props) => {
    return <Image {...props} />;
  },
  title: (props) => {
    return <Title {...props} />;
  },
  swiperTest: (props) => {
    switch (props.appearance) {
      case "articleSlider":
        return <ArticleSlider {...props} />;
      case "gridSlider":
        return <GridSlider {...props} />;
      case "imageSlider":
        return <ImageSlider {...props} />;
      case "paginatedSlider":
        return <Swiper {...props} />;
      default:
        return <></>;
    }
  },
  gridItems: (props) => {
    return <GridItems {...props} />;
  },
  gridRow: (props) => {
    return <GridRows {...props} />;
  },
  gridCheck: (props) => {
    return <GridCheck {...props} />;
  },
  miniCart: (props) => {
    return <GridMini {...props} />;
  },
  button: (props) => {
    return <Button {...props} />;
  },
  form: (props) => {
    return <Form />;
  },
  imageCart: (props) => {
    return <ImageCard {...props} />;
  },
};

export function CustomRenderer({ document }: CustomRendererProps) {
  return (
    <DocumentRenderer
      renderers={defaultElementRenderers}
      componentBlocks={customComponentRenderers}
      document={document}
    />
  );
}
