import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "./Swiper.css";
import { KeystoneImage } from "gatsby-image-keystone";

type sliderProps = {
  children: [];
  appearance: any;
};
const Content = ({ children, appearance }: sliderProps) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const articleBuilder = children.map((element: any) => {
    const featureImage = element.featureImage?.data?.image;
    return (
      <SwiperSlide key={element.featureImage.id} className="mySwipe">
        <div className=" lg:grid lg:grid-cols-2 lg:py-[3rem] bg-white xl:container text-center items-center lg:text-left  px-[20px]  xl:px-0 ">
          <div className="my-auto lg:drop-shadow-[-35px_35px_0px_rgba(170,53,17,1)] px-[1rem]">
            {featureImage && (
              <KeystoneImage
                baseUrl="fortune"
                image={{
                  alt: featureImage?.id,
                  width: 600,
                  height: 400,
                  key: `${featureImage?.id}.${featureImage?.extension}`,
                }}
                layout="fullWidth"
                alt={featureImage?.id}
              />
            )}
          </div>

          <article className="prose lg:pl-[3rem] mt-[2rem] align-middle max-w-[600px] mx-auto lg:mt-0 ">
            {/* <p>{element.content}</p>
            <h1 className="text-right">{element.title}</h1> */}
            {element.content}
          </article>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      className="mySwiper"
      pagination={pagination}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: true,
      }}
    >
      {articleBuilder}
    </Swiper>
  );
};

export default Content;
