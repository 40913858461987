import * as React from "react";

const titleStyles = {
  underlined: " border-b-[1px] border-theme pb-[2rem] my-[3rem] ",
  main: " text-left mb-[2rem] text-lightTheme",
  borderLeft:" relative mb-6 border-l-4 border-theme pl-[12px] text-dark ",
};

type TitleProps = {
  appearance: keyof typeof titleStyles;
  content: React.ReactNode;
};

export const Title = ({ appearance, content }: TitleProps) => {
  return (
    <div className="relative xl:container xl:px-0 px-5 ">
      <div className={titleStyles[appearance]}>{content}</div>
    </div>
  );
};
