import * as React from "react";
import { DocumentRenderer } from "@keystone-6/document-renderer";
import { Link } from "gatsby";
import { PostType } from "@/types";
import moment from "moment";
import convertSlug from "../../utils/convertSlug";
import { KeystoneImage } from "gatsby-image-keystone";

interface IProps {
  data: PostType;
}

const News = ({ data }: IProps) => {
  const { title, content, featureImage, publishDate } = data;

  return (
    <article className="break-inside-avoid h-auto max-w-[413px] min-w-[310px] bg-white px-[20px] pb-[40px] mx-auto md:mx-0">
      <div className="shadow-3xl">
        {featureImage && (
          <KeystoneImage
            baseUrl="fortune"
            image={{
              alt: featureImage.id,
              width: 413,
              height: featureImage.height * (413 / featureImage.width),
              key: `${featureImage.id}.${featureImage.extension}`,
            }}
            layout="fullWidth"
            alt={featureImage.id}
          />
        )}
        <div className="px-[40px] pb-[48px] pt-[40px]">
          <h3 className="relative mb-2 border-l-4 border-theme pl-[12px] text-[14px] font-[800] uppercase leading-[26px] tracking-[0.5px] text-dark">
            {" "}
            News / {moment(publishDate).format("LL")}
          </h3>
          <Link
            className="text-[24px] font-[700] text-dark transition-colors duration-300 ease-out hover:text-theme cursor-pointer"
            to={`/news/${convertSlug(title)}`}
          >
            {title}
          </Link>
          <div className="mt-2 text-[16px] font-[600] text-darkGray">
            <DocumentRenderer document={content.document} />
          </div>

          <Link to={`/news/${convertSlug(title)}`}>
            <button className=" relative mt-6 text-dark duration-500 before:absolute before:right-0 before:bottom-0 before:h-[4px] before:w-full before:bg-theme before:transition-width hover:before:left-0 hover:before:w-0 ">
              Read more
            </button>
          </Link>
        </div>
      </div>
    </article>
  );
};

export default News;
