import React from "react";
import { KeystoneImage } from "gatsby-image-keystone";

type ImageCardProps = {
  children: [];
  appearance: any;
};

export const ImageCard = ({ children, appearance }: ImageCardProps) => {
  const { width } = appearance;
  const imagePosition = {
    right: "",
    center: "",
  };

  const grid = children?.map((data: any) => {
    const image = data.featureImage.data?.image;
    return (
      //   <div className="">
      //   <StaticImage
      //     src="../../images/technology/Picture2.jpeg"
      //     className="mb-[1rem]"
      //     alt=""
      //     placeholder="blurred"
      //     width={300}
      //     height={192}
      //   />
      //   <p className="font-[700] text-[16px]">Microtrac machine</p>
      // </div>
      <div key={data.featureImage.id} className=" ">
        <div>
          {image && (
            <KeystoneImage
              baseUrl="fortune"
              image={{
                alt: image.id,
                width: parseInt(width),
                height: image.height * (parseInt(width) / image.width),
                key: `${image.id}.${image.extension}`,
              }}
              layout="constrained"
              alt={image.id}
            />
          )}
        </div>
        <div className="pt-4">{data.content}</div>
      </div>
    );
  });
  return (
    <div className="xl:container grid grid-cols-2   mx-auto md:grid-cols-3   justify-center lg:justify-start gap-[1rem]">
      {grid}
    </div>
  );
};
