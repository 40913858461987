import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

  const InputStyling = `border-[1px] mb-[1rem] border-lightGray p-[1rem] outline-none duration-300 ease-in-out focus:border-black w-full`;

export const Form = () => {
  const form = useRef();
  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "serviceId",
        "templateId",
        form.current,
        "PublicServiceId"
      )
      .then(
        (result) => {
          e.target.reset();
          toast.success("Your request was successfully submitted");
        },
        (error) => {
          toast.error("Something went wrong with submission");
        }
      );
  };

  return (
    <div>
      <form
        ref={form}
        action="/contact-us"
        method="post"
        name="contact"
        onSubmit={sendEmail}
        data-netlify={true}
        className=" mb-[4rem] flex flex-col lg:mb-0 w-full "
      >
        <div>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            className={InputStyling}
          />
        </div>
        <div>
          <input
            type="text"
            name="email"
            placeholder="Email"
            className={InputStyling}
          />
        </div>
        <div>
          <input
            type="text"
            name="telephone"
            placeholder="Telephone"
            className={InputStyling}
          />
        </div>
        <div>
          <textarea
            name="message"
            cols={30}
            rows={10}
            placeholder="Additional information"
            className={InputStyling}
          ></textarea>
        </div>
        <div>
          <button
            type="submit"
            className="max-w-max bg-black py-[0.8rem] px-[2rem] font-[600] duration-300 ease-in-out hover:bg-theme hover:text-black text-white "
          >
            Send message
          </button>
        </div>
      </form>
    </div>
  );
};
