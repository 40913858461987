import { KeystoneImage } from "gatsby-image-keystone";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

type GridProps = {
  children: [];
  appearance: any;
  title: any;
};

export const GridItems = ({ children, appearance, title }: GridProps) => {
  const { height, width, hover } = appearance;
  const imagePosition: any = {
    right: "",
    center: "",
    left: "flex",
  };
  const grid = children?.map((data: any) => {
    const image = data.featureImage.data?.image;
    return (
      <div
        key={data.content.key}
        className=" group relative h-fit lg:max-w-[300px] lg:w-auto w-[300px] bg-white p-[48px] transition-all duration-300 ease-out hover:bg-theme hover:shadow-3xl mx-auto lg:mx-0 
      "
      >
        <div className=" w-full duration-300 group-hover:-translate-y-4">
          {image && (
            <div className="mb-4 ">
              <KeystoneImage
                className="mb-4 "
                baseUrl="fortune"
                image={{
                  alt: image.id,
                  width: 88,
                  height: 88,
                  key: `${image.id}.${image.extension}`,
                }}
                layout="fixed"
                alt={image.id}
              />
            </div>
          )}
          <div className="my-auto max-w-[600px] xl:max-w-[800px]">
            {data.content}
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className="relative mx-auto h-auto">
      <StaticImage
        className="
          !absolute top-0 w-screen"
        src="../../images/overlay_dark.png"
        alt="overlay"
        placeholder="none"
      />

      <div
        className=" absolute top-0 left-[50%] z-0 min-h-fit w-screen  -translate-x-[50%]  
        xl:w-[1280px]  "
      >
        <StaticImage
          src="../../images/background/map_transparent_dark.png"
          alt=""
          className=""
          layout="fullWidth"
          placeholder="none"
        />
      </div>
      <div className="z-5 absolute top-0 left-0 h-[25%] w-screen bg-gradient-to-b from-white to-transparent"></div>

      <div className="z-1 mx-auto xl:container  pt-[72px] lg:pb-[96px] xl:px-0 px-[5%]">
        <div className="relative mb-6 border-l-4 border-theme pl-[12px]  font-[800] uppercase leading-[26px] tracking-[0.5px] text-dark ">
          {title}
        </div>
        <div className="relative flex px-0 xl:px-10 mx-auto h-auto w-full">
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-auto">
            {grid}
          </section>
        </div>
      </div>
    </div>
  );
};
